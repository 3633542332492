<route>
{
  "meta": {
    "permission": [
      "supplier.add_supplier"
    ]
  }
}
</route>

<template>
  <i-container :opts="opts" :mode="container" @input="$emit('input', $event)">
    <v-card>
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form @submit.prevent="handleSubmit(submit)">
          <i-toolbar
            :title="`${$t('create', { model: $tc('supplier', 1) })}`"
            :loading="load"
          ></i-toolbar>
          <v-card-text>
            <!-- Modales -->
            <dialterms v-model="dialog2" :to-edit="toEdit2" @done="getTerms" />

            <!-- Campos -->
            <v-row class="pt-4">
              <!-- Nombre de la compañia -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="company_name"
                  rules="required"
                  :name="$t('company_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('company_name')"
                    v-model="supplier.company_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Nombre -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="first_name"
                  rules="required"
                  :name="$t('first_name')"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('first_name')"
                    outlined
                    v-model="supplier.first_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Apellido -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="last_name"
                  :name="$t('last_name')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('last_name')"
                    outlined
                    v-model="supplier.last_name"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Correo -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="email"
                  :name="$tc('email', 1)"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$tc('email', 1)"
                    outlined
                    v-model="supplier.email"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Teléfono -->
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="phone_number"
                  :name="$t('phone_number')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    counter
                    maxlength="14"
                    outlined
                    persistent-hint
                    :error-messages="errors[0]"
                    :label="$t('phone_number')"
                    v-model="supplier.phone"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <!-- Activo -->
              <v-col cols="12" md="4">
                <v-switch
                  class="v-switch mt-2"
                  color="primary"
                  name="active"
                  :label="$t('active')"
                  v-model="supplier.active"
                ></v-switch>
              </v-col>
            </v-row>

            <!-- Impuestos -->
            <v-row
              v-if="
                getPermissions([`base_config.change_supplierglobaltax`]) ||
                isAdmin
              "
              class="mt-3"
            >
              <!-- Impuesto -->
              <v-col cols="12" md="4">
                <v-switch
                  name="tax"
                  color="primary"
                  :label="$tc('tax', 1)"
                  v-model="supplier.tax"
                ></v-switch>
              </v-col>

              <!-- Número de pago de impuestos -->
              <v-col cols="12" md="8" lg="4">
                <ValidationProvider
                  v-show="!supplier.tax"
                  vid="tax_id"
                  :name="$tc('tax_id', 1)"
                  :rules="supplier.tax ? '' : 'required|min:4|max:50'"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$tc('tax_id', 2)"
                    v-model="supplier.tax_number"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- Crédito -->
            <v-row class="mt-0">
              <!-- Crédito -->
              <v-col
                v-if="getPermissions([`base_config.add_term`]) || isAdmin"
                cols="12"
                md="4"
              >
                <v-switch
                  color="primary"
                  name="has_credit"
                  :label="$tc('has_credit', 1)"
                  v-model="supplier.has_credit"
                ></v-switch>
              </v-col>

              <!-- Límite de crédito -->
              <v-col v-show="supplier.has_credit" cols="12" md="4">
                <ValidationProvider
                  vid="credit_limit"
                  :name="$t('credit_limit')"
                  :rules="{ required: supplier.has_credit }"
                  v-slot="{ errors }"
                >
                  <i-money
                    class="secondary--text"
                    outlined
                    :error-messages="errors[0]"
                    :label="$t('credit_limit')"
                    v-model="supplier.credit[0].credit_limit"
                  ></i-money>
                </ValidationProvider>
              </v-col>

              <!-- Días para pagar -->
              <v-col cols="12" md="4" sm="4" v-show="supplier.has_credit">
                <ValidationProvider
                  vid="day_terms"
                  :name="$t('day_terms')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-select
                    class="secondary--text"
                    append-icon="fa-plus"
                    item-text="days"
                    item-value="pk"
                    outlined
                    :error-messages="errors[0]"
                    :items="days"
                    :label="$t('day_terms')"
                    @click:append="openTerms()"
                    v-model="supplier.credit[0].term"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- Dirección -->
            <v-row class="infocard mx-0">
              <v-col cols="12">
                <h4 class="primary--text text--accent-3">
                  {{ $t('company_address') }}
                </h4>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address"
                  :name="$t('address')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('address')"
                    outlined
                    v-model="supplier.address_info[0].address1"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address2"
                  :name="$t('address2')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('address2')"
                    outlined
                    v-model="supplier.address_info[0].address2"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="city"
                  :name="$t('city')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('city')"
                    outlined
                    v-model="supplier.address_info[0].city"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="state"
                  :name="$t('state')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('state')"
                    outlined
                    v-model="supplier.address_info[0].state"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="zip_code"
                  :name="$t('zip_code')"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    :error-messages="errors[0]"
                    :label="$t('zip_code')"
                    outlined
                    v-model="supplier.address_info[0].zip_code"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="country"
                  :name="$t('country')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    class="secondary--text"
                    append-icon="fa-search"
                    item-text="name"
                    item-value="pk"
                    outlined
                    :error-messages="errors[0]"
                    :items="countries"
                    :label="$t('country')"
                    v-model="supplier.address_info[0].country"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- Igualar direcciones -->
            <v-row class="mt-3 pb-3">
              <v-col cols="12">
                <v-switch
                  class="v-switch"
                  color="primary"
                  name="payment"
                  :disabled="
                    supplier.address_info[0].address1 &&
                    supplier.address_info[0].city &&
                    supplier.address_info[0].state &&
                    supplier.address_info[0].zip_code &&
                    supplier.address_info[0].country
                      ? false
                      : true
                  "
                  :label="
                    supplier.address_info[0].address1 &&
                    supplier.address_info[0].city &&
                    supplier.address_info[0].state &&
                    supplier.address_info[0].zip_code &&
                    supplier.address_info[0].country
                      ? $t('same_company_address')
                      : $t('same_company_address') +
                        ' (' +
                        $t('fill_company_address') +
                        ')'
                  "
                  v-model="supplier.samePayment"
                ></v-switch>
              </v-col>
            </v-row>

            <!-- Dirección -->
            <v-row class="infocard mx-0 mb-3">
              <v-col cols="12">
                <h4 class="primary--text text--accent-3">
                  {{ $t('dispatchAddress') }}
                </h4>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address3"
                  :name="$t('address')"
                  :rules="{ required: !supplier.samePayment }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :label="$t('address')"
                    v-model="supplier.address_info[1].address1"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="address4"
                  :name="$t('address')"
                  rules=""
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :label="$t('address2')"
                    v-model="supplier.address_info[1].address2"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="city2"
                  :name="$t('city')"
                  :rules="{ required: !supplier.samePayment }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :label="$t('city')"
                    v-model="supplier.address_info[1].city"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="state2"
                  :name="$t('state')"
                  :rules="{ required: !supplier.samePayment }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :label="$t('state')"
                    v-model="supplier.address_info[1].state"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="zip_code2"
                  :name="$t('zip_code')"
                  :rules="{
                    required: !supplier.samePayment,
                    numeric: 'numeric'
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    class="secondary--text"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :label="$t('zip_code')"
                    v-model="supplier.address_info[1].zip_code"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="4">
                <ValidationProvider
                  vid="country2"
                  :name="$t('country')"
                  :rules="{
                    required: !supplier.samePayment
                  }"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    class="secondary--text"
                    append-icon="fa-search"
                    item-text="name"
                    item-value="pk"
                    outlined
                    :disabled="supplier.samePayment"
                    :error-messages="errors[0]"
                    :items="countries"
                    :label="$t('country')"
                    v-model="supplier.address_info[1].country"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </ValidationObserver>
    </v-card>
  </i-container>
</template>
<script>
import { mapGetters } from 'vuex'
import dialterms from '../../components/terms/modal.vue'

export default {
  props: {
    container: {
      type: String,
      default: 'container'
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    dialterms
  },
  data() {
    return {
      dialog: false,
      dialog2: false,
      toEdit: null,
      toEdit2: null,
      load: false,
      loading: false,
      mode: 1,
      countries: [],
      days: [],
      infoSupplier: '',
      supplier: {
        pk: '',
        company_name: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        user: null,
        tax: true,
        tax_number: '',
        has_credit: false,
        company_id: 1,
        active: true,
        address_info: [
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'PAY'
          },
          {
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip_code: null,
            country: null,
            address_type: 'DISP'
          }
        ],
        credit: [{ credit_limit: 0, term: 1 }],
        samePayment: false
      },
      empty: false,
      created: true
    }
  },
  computed: {
    opts() {
      switch (this.container) {
        case 'modal':
          return {
            title: this.$t(this.edit ? 'editing' : 'add', {
              value: this.$tc('supplier', 1)
            }),
            value: this.value,
            load: this.loading,
            maxWidth: '80%',
            dontSave: this.mode == 1
          }
        default:
          return { fluid: true }
      }
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    openLevel(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    },
    openTerms(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog2 = true
    },
    async submit() {
      try {
        this.load = true
        if (!this.supplier.has_credit) {
          delete this.supplier.credit
        }
        this.infoSupplier = await this.$api.supplier.create({
          form: this.supplier
        })
        this.$toast.success(
          this.$t('create', { model: this.$tc('supplier', 1) })
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.load = false
        // Redirecciona siempre que no esté en modo modal
        if (this.container !== 'modal') {
          this.$router.push({ name: 'supplier' })
        }
      }
    },
    getSupplier() {
      const validation = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (validation == 'create') {
        if (this.$store.getters['utils/getIsloading']) {
          this.$store.dispatch('utils/actionLoading')
          this.$route.meta.title = this.$t('create', { model: '' })
        }
      }
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.countries = country.data
    },
    async getTerms() {
      const terms = await this.$api.terms.list({})
      this.days = terms.data.results
    }
  },
  mounted() {
    if (this.container === 'container') {
      this.getSupplier()
    }
    this.getCountries()
    this.getTerms()
  },
  watch: {
    'supplier.samePayment': {
      handler(val) {
        if (val) {
          this.supplier.address_info[1] = {
            ...this.supplier.address_info[0],
            address_type: 'DISP'
          }
        }
      }
    }
  }
}
</script>
