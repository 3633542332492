<template>
  <i-modal
    :title="$t(edit ? 'editing' : 'add', { value: $tc('terms', 1) })"
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row>
      <v-col cols="12">
        <ValidationProvider
          vid="days"
          :name="$tc('days', 1)"
          rules="required|numeric"
          v-slot="{ errors }"
        >
          <v-text-field
            autofocus
            name="days"
            :error-messages="errors[0]"
            v-model="terms.days"
            outlined
            color="secondary"
            :label="$tc('days', 1)"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
  </i-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      terms: {
        days: ''
      },
      edit: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.terms = this.toEdit !== null ? { ...this.toEdit } : { days: '' }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          const term = !this.edit
            ? await this.$api.terms.create({ form: this.terms })
            : await this.$api.terms.edit({
                pk: this.terms.pk,
                form: this.terms
              })
          this.$emit('done', term.data)
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('terms', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>
